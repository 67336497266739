export const Switcher = ({ className = "", isOn, onChange, disabled }) => {
  return (
    <div
      className={`
              flex items-center rounded-full duration-200 relative
              ${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer "}
              ${isOn ? "bg-green-500" : "bg-gray-300 dark:bg-gray-700"}
              ${className}
              `}
      style={{ width: 46, height: 26 }}
      onClick={disabled ? null : onChange}
    >
      <div style={{ width: 22, height: 22, left: isOn ? 22 : 2 }} className="rounded-full duration-200 absolute bg-white dark:bg-gray-100"></div>
    </div>
  );
};
