import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation, Route, Routes, Navigate } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "@apollo/client";
import { FETCH_RANDOM_IMAGE } from "./graphql";
import { AppContext } from "AppContainer";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import ConfirmResetPasswordForm from "./ConfirmResetPasswordForm";

const AuthPages = () => {
  const { data } = useQuery(FETCH_RANDOM_IMAGE);
  const { user } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      let redirectTo = queryString.parse(location.search).redirectTo || "/me";
      navigate(redirectTo);
    }
  }, [user, location.search, navigate]);

  return (
    <div
      className="flex items-center justify-center bg-center bg-cover h-screen"
      style={
        data && data.randomPromoImage
          ? {
              backgroundImage: `url(${
                process.env.NODE_ENV === "development"
                  ? "https://cdn.waboba.com/assets/promo_images/a79cdf555e194db9a8dbbfe5dd6bc64e-Jeff_Nass_Productions_WingmanPro_Web_Res-66.jpg"
                  : data.randomPromoImage.url
              })`,
            }
          : null
      }
    >
      <div className="p-12 md:rounded-3xl shadow-lg backdrop-blur-xl bg-gray-100 dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-95 h-full flex flex-col items-center justify-center md:h-auto w-full md:max-w-lg py-14 px-16">
        <div className="w-full">
          <div className="flex justify-center">
            <img className="h-20" src="https://wiscn.waboba.com/static/media/waboba-logo.f0f7fdc1674b5564757498a3bba0053a.svg" alt="Waboba" />
          </div>

          <div className="text-center mt-2 font-light">Waboba ID</div>

          <Routes>
            <Route>
              <Route index element={<LoginForm />} />
              <Route path="reset-password" element={<ResetPasswordForm />} />
              <Route path="reset-password/:code/:key" element={<ConfirmResetPasswordForm />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>

          <div className="opacity-60 text-xs mt-8 space-x-8 text-center">
            <span>Copyright © {new Date().getFullYear()} Waboba AB. All rights reserved.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPages;
