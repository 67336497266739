import { gql, useQuery } from "@apollo/client";
import AppRoutes from "./AppRoutes";
import { createContext, useEffect, useState } from "react";
import InitializingView from "components/InitializingView";
import ModalProvider from "components/Modal";

export const AppContext = createContext();

const FETCH_ME = gql`
  query FETCH_ME {
    me {
      id
      email
      groups {
        id
        name
      }
      isSuperuser
      isStaff
      supplier {
        id
      }
    }
  }
`;

const AppContainer = () => {
  const [user, setUser] = useState(null);
  const { data, loading } = useQuery(FETCH_ME);

  useEffect(() => {
    if (data && data.me) {
      setUser(data.me);
    }
  }, [data]);

  if (loading) return <InitializingView />;

  return (
    <AppContext.Provider value={{ user, setUser }}>
      <ModalProvider>
        <AppRoutes />
      </ModalProvider>
    </AppContext.Provider>
  );
};

export default AppContainer;
