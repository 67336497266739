import { useContext, useEffect, useState } from "react";
import Page from "components/Page";
import Button from "components/Button";
import { MakeCredReq, publicKeyCredentialToJSON } from "pages/login/auth";
import { axiosInstance } from "http";
import { AppContext } from "AppContainer";
import { useMutation, useQuery } from "@apollo/client";
import { LOGOUT } from "pages/login/graphql";
import { toast } from "react-toastify";
import { parseError } from "apollo";
import { FETCH_USER_PASSKEYS, UPDATE_PASSKEY } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import moment from "moment";
import { Switcher } from "components/Switcher";
import { BsChevronRight } from "react-icons/bs";

const UserPage = () => {
  const { loading, error, data } = useQuery(FETCH_USER_PASSKEYS);
  const { user, setUser } = useContext(AppContext);
  const [showPasskeyButton, setShowPasskeyButton] = useState(false);
  const [signout, signoutRes] = useMutation(LOGOUT, {
    onCompleted: () => {
      setUser(null);
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });
  const [updatePasskey, updatePasskeyRes] = useMutation(UPDATE_PASSKEY, {
    onCompleted() {
      toast.success("Passkey updated.");
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  useEffect(() => {
    if (
      window.PublicKeyCredential &&
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable &&
      window.PublicKeyCredential.isConditionalMediationAvailable
    ) {
      Promise.all([
        window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
        window.PublicKeyCredential.isConditionalMediationAvailable(),
      ]).then((results) => {
        if (results.every((r) => r === true)) {
          setShowPasskeyButton(true);
        }
      });
    }
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  async function createPasskey() {
    var res = await axiosInstance.get("passkeys/reg/begin");
    try {
      const publicKey = MakeCredReq(res.data);
      const credential = await navigator.credentials.create(publicKey);
      res = await axiosInstance.post("passkeys/reg/complete", JSON.stringify(publicKeyCredentialToJSON(credential)));
    } catch (error) {
      if (error.name === "NotAllowedError") return;
      toast.error(parseError(error));
    }
  }

  function onChange(id, enabled) {
    updatePasskey({ variables: { id, enabled } });
  }

  console.log("user", user);

  let showWis = user.isSuperuser || user.isStaff || user.groups.some((i) => i.name === "Internal");

  return (
    <Page
      title="Waboba Account"
      topbarRightView={
        <div>
          <Button loading={signoutRes.loading} onClick={signout}>
            Sign Out
          </Button>
        </div>
      }
    >
      <div className="p-10 grid grid-cols-12 gap-6">
        <div className="col-span-9 space-y-6">
          <div className="card">
            <div>Waboba ID is a single sign-on service that allows you to sign in to many different Waboba services with one account.</div>

            <div className="flex space-x-6 mt-6">
              {showWis && (
                <a href="https://wis.waboba.com" className="flex items-center space-x-2">
                  <span>Waboba WIS</span>
                  <span>
                    <BsChevronRight />
                  </span>
                </a>
              )}

              <a href="https://biz.waboba.com" className="flex items-center space-x-2">
                <span>Waboab BIZ</span>
                <span>
                  <BsChevronRight />
                </span>
              </a>
              {user.supplier && (
                <a href="https://supplier.waboba.com" className="flex items-center space-x-2">
                  <span>Supplier Portal</span>
                  <span>
                    <BsChevronRight />
                  </span>
                </a>
              )}
            </div>
          </div>

          {showPasskeyButton ? (
            <div className="card">
              <h4>Passkeys</h4>
              <div className="mt-4">
                {data.me.passkeys.length > 0 ? (
                  <div className="-mx-2">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Platform</th>
                          <th>Added on</th>
                          <th>Last used</th>
                          <th className="text-right">Enabled</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.me.passkeys.map((i) => (
                          <tr key={i.id}>
                            <td>{i.name}</td>
                            <td>{i.platform}</td>
                            <td>{moment(i.addedOn).format()}</td>
                            <td>{moment(i.lastUsed).format()}</td>
                            <td>
                              <div className="flex justify-end">
                                <Switcher disabled={updatePasskeyRes.loading} isOn={i.enabled} onChange={() => onChange(i.id, !i.enabled)} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="text-center">
                    <p className="text-gray-500">No passkeys found.</p>
                  </div>
                )}

                <Button className="mt-4" onClick={createPasskey}>
                  Create Passkey
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Page>
  );
};

export default UserPage;
